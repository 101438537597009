<template>
  <div class="container-fluid py-4 add-student add-receipt">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">تعديل إيصال</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="updateReceipt" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    نوع الحركة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.receipt_type_id ? errors.receipt_type_id[0] : ''
                    }}</span></label
                  >
                  <div class="input-group input-group-outline null">
                    <select class="form-control" v-model="selectedType.id">
                      <option selected value="">اختر النوع</option>
                      <option
                        v-for="type in receiptTypes"
                        :key="type.id"
                        :value="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="mb-3 col-lg-6 radio-row bg-gradient-secondary">
                  <label class="radio-label">
                    <span color="danger" class="error">{{
                      errors.transaction_type_id
                        ? errors.transaction_type_id[0]
                        : ''
                    }}</span></label
                  >
                  <div
                    class="form-check"
                    v-for="item in transactionTypes"
                    :key="item.id"
                  >
                    <input
                      class="form-check-input"
                      :name="item.name"
                      type="radio"
                      :value="item.id"
                      v-model="selectedTransaction.id"
                      @change="changColor(item.name, item.id)"
                    />
                    <label class="form-check-label" :for="item.name">
                      {{ item.name }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row align-center">
                <div class="mb-3 col-lg-3">
                 <label>  ادخل رقم الطالب أو الهاتف<span class="red">*</span></label>
                  <vmd-input
                    id="student-number"
                    type="text"
                     placeholder="  رقم الطالب أو الهاتف "
                    v-model="student.number"
                    @input="search"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> اسم الطالب </label>
                  <vmd-input
                    id="student-name"
                    type="text"
                    v-model="student.full_name"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> رقم هاتف الطالب </label>
                  <vmd-input
                    id="student-mobile"
                    type="text"
                    v-model="student.mobile"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    اسم الدورة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.payabel_id ? errors.payabel_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="grade"
                    v-model="selectedCourse"
                    :options="courses"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder=" اختر الدورة"
                    label="name"
                    track-by="id"
                    required="required"
                    @select="getActiveLectures"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6" v-if="receipt.attend">
                  <label>
                  المحاضرة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.lecture_id ? errors.lecture_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="grade"
                    v-model="selectedLecture"
                    :options="activeLectures"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder=" اختر محاضرة"
                    label="name"
                    track-by="id"
                    required="required"
                    @select="checkStudentEnroll"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد محاضرات اليوم </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    القيمة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.amount ? errors.amount[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="number"
                    v-model="receipt.amount"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    البيان <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.description ? errors.description[0] : ''
                    }}</span></label
                  >
                  <vmd-input id="" type="text" v-model="receipt.description" />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <div class="online attend bg-gradient-secondary">
                    <vmd-checkbox
                      :checked="receipt.attend"
                      @change="changeReceiptAmount"
                      v-model="receipt.attend"
                    ></vmd-checkbox>
                    <span> ايصال حضور </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    تعديل</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="button"
                    class="my-4 mb-2"
                    variant="gradient"
                    @click="goBack()"
                    color="danger"
                  >
                    رجوع</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div>
      <PrintReceipt :receipt="receipt"></PrintReceipt>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import PrintReceipt from '@/components/PrintReceipt.vue'
import swal from 'sweetalert'
import $ from 'jquery'

const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
let timeout = null

export default {
  name: 'AddReceipt',
  components: {
    PrintReceipt,
    VmdInput,
    VmdButton,
    VmdCheckbox
  },
  data () {
    return {
      receipt: {
        number: null,
        full_name: null,
        amount: '',
        title: '',
        description: 'سداد',
        user_name: localStorage.userName,
        branch: localStorage.branchName,
        trans: '2a187fd8-574f-4260-8e18-cd75ccea891f'
      },
      students: [],
      courses: [],
      student: {
        id: null,
        full_name: null,
        mobile: null,
        number: null
      },
      selectedCourse: '',
      selectedLecture: '',
      activeLectures: [],
      receiptTypes: [],
      transactionTypes: [],
      selectedTransaction: '',
      selectedStudent: '',
      selectedType: '',
      enrolled: '',
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}receipts/${this.$route.params.id}`).then(response => {
      this.receipt = response.data.data
      this.student = this.receipt.student
      this.student = {
        id: this.receipt.student.id,
        number: this.receipt.student.number,
        full_name: this.receipt.student.full_name,
        mobile: this.receipt.student.mobile
      }
      this.selectedType = {
        id: this.receipt.receipt_type.id,
        name: this.receipt.receipt_type.name
      }
      this.selectedTransaction = {
        id: this.receipt.transaction_type.id,
        name: this.receipt.transaction_type.name
      }
      if (this.receipt.lecture) {
        this.receipt.attend = 1
        this.selectedLecture = {
          id: this.receipt.payable ? this.receipt.payable.id : '',
          name: this.receipt.payable ? this.receipt.payable.lecture_number : ''
        }
        this.selectedCourse = {
          id: this.receipt.payable.course ? this.receipt.payable.course.id : '',
          name: this.receipt.payable.course ? this.receipt.payable.course.name : ''
        }
      } else {
        this.receipt.attend = 0
        this.selectedCourse = {
          id: this.receipt.payable ? this.receipt.payable.id : '',
          name: this.receipt.payable ? this.receipt.payable.name : ''
        }
      }
      if (this.selectedTransaction.name === 'استلام') {
        $('.radio-row').addClass('bg-gradient-success')
      } else {
        $('.radio-row').addClass('bg-gradient-danger')
      }
    })
    const receiptTypes = []
    this.$http.get(`${this.$hostUrl}receipt-types`).then((response) => {
      $.each(response.data.data, function (key, value) {
        receiptTypes.push({
          name: value.name,
          id: value.id
        })
      })
      this.receiptTypes = receiptTypes
    })
    const transactionTypes = []
    this.$http.get(`${this.$hostUrl}transaction-types`).then((response) => {
      $.each(response.data.data, function (key, value) {
        transactionTypes.push({
          name: value.name,
          id: value.id
        })
      })
      this.transactionTypes = transactionTypes
    })
    const courses = []
    this.$http.get(`${this.$hostUrl}courses`).then((response) => {
      $.each(response.data.data, function (key, value) {
        courses.push({
          name: value.name,
          id: value.id
        })
      })
      this.courses = courses
    })
    const activeLectures = []
    this.$http.get(`${this.$hostUrl}lectures`).then((response) => {
      $.each(response.data.data, function (key, value) {
        activeLectures.push({
          name: value.lecture_number,
          id: value.id
        })
      })
      this.activeLectures = activeLectures
    })
  },
  methods: {
    async checkStudentEnroll (event) {
      console.log('this.student.id', this.student.id)
      console.log('this.selectedCourse.id', this.selectedCourse)
      if (!this.student.id || !this.selectedCourse.id) {
        this.errors.lecture_id = []
        this.errors.lecture_id.push('الرجاء ادخال بيانات الطالب واختيار الدورة اولا')
        this.selectedLecture = ''
        console.log(event)
        return false
      }
      const current = this
      await this.$http
        .get(`${this.$hostUrl}enrolled/${event.id}/${this.student.id}`)
        .then((response) => {
          this.enrolled = response.data.data.enrolled
          if (!response.data.data.enrolled) {
            current.receipt.description = 'حضور استثنائي'
          } else {
            current.receipt.description = 'سداد'
          }
        })
    },
    changeReceiptAmount (event) {
      if (this.receipt.attend) {
        this.receipt.amount = 10
      } else {
        this.receipt.amount = ''
      }
    },
    getActiveLectures (event) {
      const activeLectures = []
      this.$http
        .get(`${this.$hostUrl}active-lectures?date=${today}&course_id=${event.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, val) {
            console.log(val)
            activeLectures.push({
              name: val.lecture_number,
              lecture_number: val.lecture_number,
              courseName: val.course_name,
              course: {
                id: val.course.id,
                name: val.course_name
              },
              grade: val.grade,
              id: val.id
            })
          })
          this.activeLectures = activeLectures
        })
    },
    goBack () {
      this.$router.push({ path: '/receipts' })
    },
    search () {
      this.student.id = null
      this.student.full_name = null
      this.student.grade_name = null
      this.student.mobile = null
      this.selectedLecture = ''
      if (!this.student.number.length) {
        return false
      }
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.$http
          .get(`${this.$hostUrl}students-list?search=${this.student.number}`)
          .then((response) => {
            if (response.data.data.length === 1) {
              this.student.full_name = response.data.data[0].full_name
              this.student.mobile = response.data.data[0].mobile
              this.student.id = response.data.data[0].id
              this.student.grade_name = response.data.data[0].grade_name
            } else {
              this.student.id = null
              this.student.full_name = null
              this.student.mobile = null
              this.student.grade_name = null
            }
          })
      }, 800)
    },
    changColor (name, id) {
      this.receipt.trans = id
      if (name === 'استلام') {
        $('.radio-row').addClass('bg-gradient-success')
        $('.radio-row').removeClass('bg-gradient-danger')
      } else {
        $('.radio-row').addClass('bg-gradient-danger')
        $('.radio-row').removeClass('bg-gradient-success')
      }
    },
    showReceipt () {
      this.$router.push({ path: '/receipts/show' })
    },
    async updateReceipt (e) {
      const current = this
      e.preventDefault()
      const formData = new FormData()
      if (!this.selectedCourse) {
        this.errors.payabel_id = []
        this.errors.payabel_id.push('الرجاء اختيار الدورة أولا')
        return 0
      }
      if (!this.selectedType.id) {
        this.errors.receipt_type_id = []
        this.errors.receipt_type_id.push('الرجاء اختيار نوع الحركة أولا')
        return 0
      }
      console.log(this.receipt.trans)
      if (!this.selectedTransaction.id) {
        this.errors.transaction_type_id = []
        this.errors.transaction_type_id.push('الرجاء اختيار نوع الإيصال أولا')
        return 0
      }
      if (this.receipt.attend && !this.selectedLecture) {
        this.errors.lecture_id = []
        this.errors.lecture_id.push('الرجاء اختيار محاضرة لإيصالات الحضور')
        return 0
      }
      if (this.receipt.attend) {
        formData.append('payable_id', this.selectedLecture.id)
      } else {
        formData.append('payable_id', this.selectedCourse.id)
      }
      formData.append('payable_id', this.selectedCourse.id)
      this.receipt.course = this.selectedCourse.name
      formData.append('student_id', this.student.id)
      this.receipt.full_name = this.student.full_name
      formData.append('receipt_type_id', this.selectedType.id)
      formData.append('transaction_type_id', this.selectedTransaction.id)
      formData.append('amount', this.receipt.amount)
      formData.append('exceptional', this.enrolled ? 0 : 1)
      if (this.receipt.description) {
        formData.append('description', this.receipt.description)
      }
      formData.append('attend', this.receipt.attend ? 1 : 0)
      formData.append('_method', 'PUT')
      await this.$http
        .post(this.$hostUrl + 'receipts/' + this.$route.params.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            swal({ title: response.data.message, buttons: 'تم' })
            current.receipt = response.data.data
            current.receipt.attend = current.receipt.lecture ? 1 : 0
          }
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            console.log('error.response.data.data', error.response.data.data)
            if (error.response.data.data) {
              current.errors = error.response.data.data
            } else {
              current.errors = [error.response.data.message]
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      if (this.errors.length === 0) {
        setTimeout(
          this.$htmlToPaper(
            'print-receipt'
          ),
          10000
        )
      }
    }
  }
}
</script>
<style>
.radio-row {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  margin-top: 28px !important;
  border-radius: 20px;
  gap: 30px;
  position: relative;
}
.custom-control-label {
  float: left !important;
}
.attend {
  padding: 5px 25px;
  width: 45%;
  color: #fff;
  font-weight: 700;
  border-radius: 20px;
  font-weight: bold;
}
.check:not(.form-switch) .form-check-input[type='radio']:checked {
  border: 1px solid #222 !important;
}
.green-type {
  background: #78f178 !important;
}
.radio-label {
  position: absolute;
  top: -30px;
}
</style>
